const colors = {
  white100: '#fff',
  grey100: '#7F879E',
  black100: '#1B1D20',
  cyan100: '#15E4DA',
  purple100: '#7800F0',
  purpleAlt100: '#9850FF',
  purple20: '#F2EAFF',
  cream100: '#F1F4F8',
  darkBlue100: '#1F2D3E'
};
colors.background = colors.white100;
colors.primary = colors.purple100;
colors.text = colors.grey100;
colors.heading = colors.black100;

export default {
  space: [0, 4, 8, 16, 24, 32, 40, 64, 80, 128, 256, 512],
  fonts: {
    body: 'Poppins, system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [12, 14, 16, 20, 38, 54, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700
  },
  lineHeights: {
    body: 1.6,
    heading: 1.2
  },
  radii: {
    medium: 16,
    large: 24,
    xlarge: 10000
  },
  shadows: {
    heading: '0px 0px 20px rgba(0, 0, 0, 0.3)',
    purple: '0px 2px 8px rgba(120,0,240,0.3)',
    small: '0px 8px 24px rgba(0, 0, 0, 0.08)',
    large: '0px 20px 40px rgba(0, 0, 0, 0.5)'
  },
  colors,
  buttons: {
    primary: {
      fontSize: '1',
      fontWeight: 600,
      color: 'white100',
      borderRadius: 'xlarge',
      bg: 'purple100',
      border: '2px solid',
      borderColor: 'transparent',
      boxShadow: 'purple'
    },
    transparent: {
      fontSize: '1',
      fontWeight: 600,
      color: 'white100',
      borderRadius: 'xlarge',
      bg: 'rgba(255,255,255,0.4)',
      border: '2px solid',
      borderColor: 'white100'
    }
  },
  styles: {
    root: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5
    },
    h2: {
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4
    },
    h3: {
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3
    },
    h4: {
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    },
    a: {
      color: 'primary'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    }
  }
};
